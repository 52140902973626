<template>
  <base-modal-view title="Error" :message="getError()" @cancel="cancelClicked">
    <div class="flex-row">
      <p class="body-2 margin-top margin-left-auto">{{ index + 1 }} / {{ errors.length }}</p>
    </div>
    <div class="flex-row margin-top-025">
      <primary-button v-on:click="clearAllClicked" class="flex-grow" title="Clear All" />
      <div class="flex-row margin-left" v-if="errors.length > 1">
        <secondary-button title="Previous" v-if="index > 0" class="min-width-96" v-on:click="decrementError()" />
        <secondary-button
          title="Next"
          v-if="index < errors.length - 1"
          class="margin-left-025 min-width-96"
          v-on:click="incrementError()"
        />
      </div>
    </div>
  </base-modal-view>
</template>

<script>
import PrimaryButton from "../../Buttons/v2/PrimaryButton.vue";
import SecondaryButton from "../../Buttons/v2/SecondaryButton.vue";
import BaseModalView from "./BaseModalView.vue";

export default {
  name: "ErrorModal",
  components: { PrimaryButton, SecondaryButton, BaseModalView },
  props: {
    errors: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
      index: 0
    };
  },
  methods: {
    cancelClicked: function () {
      this.$emit("cancel", this.index);
      if (this.index > 0) {
        this.index -= 1;
      }
    },
    clearAllClicked: function () {
      this.$emit("cancelAll");
    },
    incrementError: function () {
      if (this.index < this.errors.length - 1) {
        this.index += 1;
      }
    },
    decrementError: function () {
      if (this.index > 0) {
        this.index -= 1;
      }
    },
    getError: function () {
      if (!this.errors) {
        return "Nothing went wrong...";
      }

      let error = this.errors[this.index];

      if (error) {
        if (error.response) {
          if (error.response.data?.error) {
            return error.response.data.error;
          } else if (error.response.data?.detail) {
            return error.response.data?.detail;
          } else if (typeof error.response.data === "object") {
            var errorMessage = "";
            for (const [key, value] of Object.entries(error.response.data)) {
              errorMessage += key;
              errorMessage += ": ";
              errorMessage += value;
              errorMessage += "\n";
            }
            return errorMessage;
          }
        }
        if (error.message) {
          return error.message;
        }
        return error;
      }
      return "Something went wrong...";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/base.scss";
</style>
